<template>
  <main id="content" role="main" :key="keyIndex">
    <section class="report_group">
      <section class="report_box">
        <div class="contest-apply step2" v-if="currentTabId == 1">
          <div class="step">
            <ul>
              <li>1. {{ $t('content.accelerating.academy.Common.txt12') }}</li>
              <li>2. {{ $t('content.accelerating.academy.Common.txt07') }}</li>
            </ul>
          </div>
          <div class="form">
            <form name="">
              <template v-for="(item, index) in competitionItems">
                <div class="title" :class="index > 0 ? 'title2':''">{{ item.name }}</div>
                <table>
                  <tbody>
                    <template v-for="(info, infoIndex) in item.infos">
                      <tr>
                        <th>{{ info.name }} <em v-if="info.isRequisiteItem" class="required-mark">*</em></th>  
                      </tr>
                      <tr>
                        <td v-if="info.type == 1">
                        <template v-for="input of info.inputs">
                            {{ competitionItems[index].infos[infoIndex].inputs[0].answer == input.id ? input.name : '' }}
                        </template>
                        </td>
                        <td v-if="info.type == 2">
                          {{ getAnswerCheckbox(info.inputs) }}
                          <!-- <template v-for="(input, idx) of info.inputs">
                            {{ input.answer == 1 ? input.name : '' }}
                          </template> -->
                        </td>
                        <td v-if="info.type == 3">
                          {{ getAnswerText(info.inputs) }}
                          <!-- <template v-for="input of info.inputs">
                            {{ input.answer }}
                          </template> -->
                        </td>
                        <td v-if="info.type == 4">
                          {{ getAnswerFileName(info.inputs) }}
                          <!-- <template v-for="input of info.inputs">
                            {{ input.fileName }}
                          </template> -->
                        </td>  
                      </tr>
                    </template>
                  </tbody>
                </table>
              </template>
              <div class="scroll-box">
                <div>
                  <TinyViewr :description="accApplyPgmMngDetail.pledge" ></TinyViewr>
                </div>
              </div>
              <div class="chk-box">
                <input type="checkbox" name="" id="exchk" v-model="isPledge"/>
                <label for="exchk">{{ $t('content.accelerating.academy.DisplayInfo.txt16') }}</label>
              </div>
              <temaplate v-if="accApplyPgmMngDetail.useMarketing">
                <div class="scroll-box">
                  <div>
                    <TinyViewr :description="accApplyPgmMngDetail.useMarketing" ></TinyViewr>
                  </div>
                </div>
                <div class="chk-box">
                  <input type="checkbox" name="" id="exchk2" v-model="isUseMarketing"/>
                  <label for="exchk2">{{ $t('content.accelerating.academy.DisplayInfo.txt16') }}</label>
                </div>
              </temaplate>
            </form>
          </div>
        </div>
        <div class="contest-apply step1" v-else>
          <div class="step">
            <ul>
              <li>1. {{ $t('content.accelerating.academy.Common.txt12') }}</li>
              <li>2. {{ $t('content.accelerating.academy.Common.txt07') }}</li>
            </ul>
          </div>
          <template v-for="(item, itemIndex) in competitionItems">
          <div class="title" :class="itemIndex > 0 ? 'title2':''">{{ item.name }}</div>
          <div class="form">
            <form name="">
              <table>
                <tbody>
                  <template class="sec-table" v-for="(info, infoIndex) in item.infos">
                  <!-- type=radio 일 때 tr -->
                  <template v-if="info.type == 1">
                    <tr>
                      <th>{{ info.name }} <em v-if="info.isRequisiteItem" class="required-mark">*</em></th>
                    </tr>
                    <tr>
                      <td class="unit-radio">
                        <template v-for="input of info.inputs">
                          <div class="radio-box">
                            <input type="radio" 
                              v-model="info.inputs[0].answer"
                              :name="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}`"
                              :id="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`"
                              :value="input.id" />
                            <label :for="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`">{{ input.name }}</label>
                          </div>
                        </template>
                        <div class="input-error-desc" v-if="info.inValid">
                          <span> {{ info.msg }} </span>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <!-- type=checkbox 일 때 tr -->
                  <template v-if="info.type == 2">
                    <tr>
                      <th>{{ info.name }} <em v-if="info.isRequisiteItem" class="required-mark">*</em></th>
                    </tr>
                    <tr>
                      <td class="unit-chk">
                        <template v-for="(input, inputIndex) of info.inputs">
                          <div class="chk-box">
                            <input type="checkbox" 
                              :id="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`"
                              v-model="input.answer"
                              :value="1" />
                            <label :for="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`">{{ input.name }}</label>
                          </div>
                        </template>
                        <div class="input-error-desc" v-if="info.inValid">
                          <span> {{ info.msg }} </span>
                        </div>
                      </td>
                    </tr>
                  </template>
                   <!-- type=type 일 때 tr -->
                    <template v-if="info.type == 3">
                      <tr>
                        <th>{{ info.name }} <em v-if="info.isRequisiteItem" class="required-mark">*</em></th>
                      </tr>
                      <tr>
                        <td class="unit-text">
                          <template v-for="(input, inputIndex) of info.inputs">
                            <div class="inp-box">
                              <input type="text" name="" class="type-text" 
                                v-model="input.answer" 
                                :id = "`input_${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`"
                                :class="input.inValid ? 'input-error':''"
                                @blur="checkValidText(input, info.isRequisiteItem, info.name, `${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`)"
                                :placeholder="input.name"
                                maxlength="300" />
                            </div>
                            <div class="input-error-desc" v-if="info.isRequisiteItem" :id="`error_${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`">
                              <span v-if="input.inValid"> {{ input.msg }} </span>
                            </div>
                          </template>
                        </td>
                      </tr>
                    </template>
                    <!-- type=file 일 때 tr -->
                    <template v-if="info.type == 4">
                      <tr>
                        <th>{{ info.name }} <em v-if="info.isRequisiteItem" class="required-mark">*</em>
                          <button v-if="info.fileList.length > 0" class="share_link" @click.prevent="fileDown(info.fileList)">{{ $t('content.common.button.btn17')}} </button>
                        </th>
                      </tr>
                      <tr>
                        <td class="unit-file">
                          <template v-for="(input, inputIndex) of info.inputs">
                            <div class="file-box input-file-container">
                              <input type="text" readonly="readonly" class="type-text upload-name" disabled="disabled" :placeholder="info.option"
                                v-model="input.fileName"
                                :class="input.inValid ? 'input-error':''"
                                :id="`F${itemIndex}_${infoIndex}_${inputIndex}`" />
                              <button v-if="input.answer" type="button" class="btn-del" @click.prevent="removeEtcInfoFile(`F${itemIndex}_${infoIndex}_${inputIndex}`, info.name)"><span>삭제</span></button>
                              <button type="button" class="btn btn-line" for="FBINPUT" @click.prevent="findFile(`F${itemIndex}_${infoIndex}_${inputIndex}`)">{{ $t('content.common.button.btn12') }}</button>
                              <template v-if="info.option">
                                <input type="file" :ref="`F${itemIndex}_${infoIndex}_${inputIndex}`" :id="`F${itemIndex}_${infoIndex}_${inputIndex}`" style="display:none" @change="onFileChange" :accept="getOption(info.option)" />
                              </template>
                              <template v-else>
                                <input type="file" :ref="`F${itemIndex}_${infoIndex}_${inputIndex}`" :id="`F${itemIndex}_${infoIndex}_${inputIndex}`" style="display:none" @change="onFileChange" accept=".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf" />
                              </template>
                            </div>
                            <input type="hidden" v-model="input.answer" />
                            <div class="input-error-desc">
                              <span v-if="input.inValid"> {{ input.msg }} </span>
                            </div>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </form>
          </div>
          </template>
        </div>
 
        <!-- 버튼 -->
        <div class="btn-wrap type2">
          <BackButton @click="onBack"/>
          <BaseButton type="line" @click="save(true)">{{$t('content.common.button.btn01')}}</BaseButton>
          <BaseButton v-if="currentTabId == 1" type="line" @click="doPrev">{{$t('content.common.button.btn03')}}</BaseButton>
          <BaseButton v-if='currentTabId == 0' @click="doNext">{{$t('content.common.button.btn02')}}</BaseButton>
          <BaseButton v-if='currentTabId == 1' @click="save()">{{$t('content.accelerating.academy.Common.txt01')}}</BaseButton>
        </div>

      </section>
    </section>
  </main>
</template>

<script>

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) - 1단계 : 회사정보
 */
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import ProgramsApplicationService from '../service/ProgramsApplicationService';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';
import FileInput from '@/components/base/FileInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BackButton from '@/components/base/BackButton.vue';
import TinyViewr from '@/components/base/TinyViewer.vue';
import {
  required
} from 'vee-validate/dist/rules';
import JSZip from '@/common/jszip.min';
import FileSaver from '@/common/FileSaver.min';

extend('requiredMsg', {
  ...required,
  params: ['name'],
  validate(val, { name }) {
    console.log('size', val, name)
    if (!val) {
      if (name) {
        return `${name}을 입력해 주세요.`
      } else {
        return `입력해 주세요.`
      }
    }
    else return true
  },
});
extend('requiredChkMsg', {
  ...required,
  params: ['name','id','size'],
  validate(val, { name, id, size }) {
    console.log(name, id, size)
    if (document.querySelectorAll(`input[name=${id}]:checked`).length == 0) {
      return `${name}을 입력해 주세요.`
    }
    if (size) {
      if (document.querySelectorAll(`input[name=${id}]:checked`).length > size) {
        return `${size}개 이하로 선택하세요`
      }
    }
    return true
  },
});

export default {
  name: 'ProgramsApplyForm',
  mixins: [mixinHelperUtils],
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseLabelElement,
    BaseInputElement,
    BaseCheckbox,
    BaseRadioButton,
    FileInput,
    BaseButton,
    BackButton,
    TinyViewr
  },

  props: {
    /**
     * ACC지원프로그램 관리번호 (공모전 고유번호)
     */
    accPgmMngNo: {
      type: Number,
      required: true,
    },
  },

  computed: {
    /**
     * 공모 지원 불가능 여부
     * @type {boolean}
     */
     isNotApplicable() {
      return !this.accApplyPgmMngDetail.isShow
      || !this.accApplyPgmMngDetail.isState // 모집중지 상태이거나
      || (
        !this.accApplyPgmMngDetail.isAlwaysRecruitment // 상시 모집이 아니면서
        && !this.$moment().tz('Asia/Seoul')
          .isBetween(
            this.$moment(`${this.accApplyPgmMngDetail.startDate} ${this.accApplyPgmMngDetail.startTime}`, 'YYYYMMDD HHmm').tz('Asia/Seoul'),
            this.$moment(`${this.accApplyPgmMngDetail.endDate} ${this.accApplyPgmMngDetail.endTime}`, 'YYYYMMDD HHmm').tz('Asia/Seoul'),
          ) // 모집 기간(시작일시, 종료일시) 중이 아닌 경우
      );
    }
  },

  data() {
    return {
      init: false,
      keyIndex: 0,
      /**
       * Acc지원프로그램 관리 상세
       * @type {import('../dto/AccApplyPgmMngDetailDto').default}
       */
      accApplyPgmInfo: undefined,
      accApplyPgmMngDetail: {pledge: null},
      competitionItems: [],
      /**
       * Acc지원 임시저장 정보
       * @type {AccApplyTemporaryDto}
       */
      accApplyTemporary: undefined,
      /**
       * Acc지원 정보
       * @type {AccApplyDto}
       */
      accApply: true,

      accInfo: {
      },
      userInfo: null,
      tmpIndex: null,
      currentTabId: 0,
      // currentTabItem: [],
      isPledge: false,
      isUseMarketing: false,
      checkMime: ['hwp', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'csv', 'jpg', 'jpeg', 'gif', 'png', 'bmp', 'pdf']
    };
  },
  mounted() {
    this.init = true
  },
  async created() {
    this.userInfo = this.$store.getters.getUserInfo();

    // 등록 항목을 가져온다.
    this.programsApplicationService = new ProgramsApplicationService(this.portalApiClient);
    this.accApplyPgmMngDetail = await this.programsApplicationService.getAccApplyPgmMngDetail(this.accPgmMngNo);
    console.log('accApplyPgmMngDetail', this.accApplyPgmMngDetail)
    if (this.accApplyPgmMngDetail && this.accApplyPgmMngDetail.id) {
      // if (this.isNotApplicable) {
      //   this.showAlert(
      //     // 현재 '공모전명'에 지원이 불가능합니다.
      //     this.$t(
      //       'content.accelerating.Common.txt26',
      //       [this.accApplyPgmMngDetail.title],
      //     ),
      //     () => {
      //       this.$router.replace({
      //         name: 'Home',
      //       });
      //       // this.$router.go(-1);
      //       // this.onBack();
      //     },
      //   );
      //   return;
      // }

      const tmp = await this.programsApplicationService.getTempAccApplyMember(this.accPgmMngNo, this.userInfo.id);
      console.log('tmp', tmp)
      if (tmp) {
        if (tmp.isState === 1) { // 737: 이미 지원한 내역이 있습니다.
          this.showAlert(
            // 기존에 지원완료된 데이터가 있습니다. 중복지원은 불가능합니다.
            this.$t('content.accelerating.academy.ApplyComplete.txt05'),
            () => {
              // 공모 지원 내역으로 이동
              this.$router.replace({
                name: 'ProgramsMyApplyList',
              });
            },
          );
          return;
        }
        if (tmp.isState === 2) {
          this.accApplyTemporary = []
          this.tmpIndex = null
        } else {
          this.accApplyTemporary = tmp.items
          this.tmpIndex = tmp.id
        }
      } else {
        this.accApplyTemporary = []
        this.tmpIndex = null
      }

      console.log('getTempAccApplyMember', this.accApplyTemporary)
      if (this.accApplyTemporary && this.accApplyTemporary.length > 0) {
        
        // 임시저장 정보가 있다면
        this.showConfirm(
          // 임시 저장된 지원서 내용을 불러오시겠습니까?
          this.$t('content.accelerating.academy.ApplyTemporary.txt01'),
          {
            buttons: [
              // 취소 버튼
              {
                title: this.$t('content.common.button.btn05'),
                type: 'line',
                class: '',
                handler: () => {
                  this.onBack();
                  // this.$router.push({
                  //   name: 'Home',
                  // });
                },
              },
              // 새로 작성 버튼
              {
                title: this.$t('content.accelerating.academy.ApplyTemporary.txt04'),
                type: 'line',
                class: '',
                handler: () => {
                  console.log('new')
                  this.accApplyTemporary = []
                },
              },
              // 불러오기 버튼
              {
                title: this.$t('content.common.button.btn11'),
                class: '',
                handler: async () => {
                  this.setAnswer();
                },
              },
            ],
          },
        );
      }  
    }
    
    this.competitionItems = await this.programsApplicationService.getAccApplyPgmMngItemList(this.accPgmMngNo);
    console.log('competitionItems', this.competitionItems.length)
    if (this.competitionItems.length > 0) {
      this.currentTabId = 0;
      // this.currentTabItem = this.competitionItems[this.currentTabId];
    }
    // console.log('currentTabItem', this.currentTabItem)
    document.addEventListener('keydown', this.prohibiteRefresh);
  },

  destroyed() {
    document.removeEventListener('keydown', this.prohibiteRefresh);
  },

  watch: {
    
  },

  methods: {
    /**
     * Back 이벤트 처리
     */
    onBack() {
      this.showConfirm(
        this.$t('content.accelerating.Common.txt28'),
        () => {
          // 상세 페이지로 이동
          this.$router.replace(`/contest/detail/${this.accPgmMngNo}`)
        },
      );
    },
    async save(isTmp = false) {
      if (!isTmp && !this.isPledge) {
        this.showAlert(this.$t('content.accelerating.alert.txt46'));
        return
      }
      let   response = []
      for (const item of this.competitionItems) {
        for (const info of item.infos) {
          if (info.type == 1) {
            response.push( {competitionId: info.competitionId, 
                        competitionItemId: info.competitionItemId, 
                        competitionItemInfoId: info.id, 
                        id: 0, 
                        memberId: this.userInfo.id,
                        answer: info.inputs[0].answer
                    });
          } else if (info.type == 2) {
            for (const input of info.inputs) {
              response.push( {competitionId: input.competitionId, 
                          competitionItemId: input.competitionItemId, 
                          competitionItemInfoId: input.competitionItemInfoId, 
                          id: input.id,
                          memberId: this.userInfo.id,
                          answer: input.answer
                      });
            }
          } else if (info.type == 3) {
            for (const input of info.inputs) {
              response.push( {competitionId: input.competitionId, 
                          competitionItemId: input.competitionItemId, 
                          competitionItemInfoId: input.competitionItemInfoId, 
                          id: input.id,
                          memberId: this.userInfo.id,
                          answer: input.answer
                      });
            }
          } else if (info.type == 4) {
            for (const input of info.inputs) {
              response.push( {competitionId: input.competitionId, 
                          competitionItemId: input.competitionItemId, 
                          competitionItemInfoId: input.competitionItemInfoId, 
                          id: input.id,
                          memberId: this.userInfo.id,
                          answer: input.answer
                      });
            }
          }
        }
      }

      const formData = {};
      formData.id = this.tmpIndex;
      formData.competitionId = this.accPgmMngNo;
      formData.memberId = this.userInfo.id;
      formData.items = response;
      formData.isState = isTmp ? 0:1
      formData.isUseMarketing = this.isUseMarketing;
      
      if (isTmp) {
        const res = this.portalApiClient({
          url: `/api2/competition/response`,
          data: formData
        }).then(({ data, status }) => {
          console.log('save', data.data.id)
          if (data.result) {
            this.tmpIndex = data.data.id
            if (status === 200) {
              // 임시저장되었습니다.
              this.showAlert(this.$t('content.accelerating.alert.txt02'));
            } else {
              this.showAlert(`데이터저장 중 오류가 발생했습니다.<br>(오류코드: ${status} )`);
            }
          }
        });
      } else {
        this.showConfirm(this.$t(
          'content.accelerating.Common.txt27',
          [this.accApplyPgmMngDetail?.title],
        ), () => {
          const res = this.portalApiClient({
            url: `/api2/competition/response`,
            data: formData
          }).then(({ data }) => {
            if (data.result) {
              ///programs/application/19/apply/complete
              // this.$router.push({name: 'ProgramsApplyCompleted'});
              this.showAlert(this.$t('content.accelerating.academy.ApplyComplete.txt01'));
              this.$router.push({name: 'ContestList'});
            }
          });
        });
      }
    },
    doPrev() {
      if (this.currentTabId > 0) {
        --this.currentTabId;
        ++this.keyIndex;
      }
    },
    setErrorText(id, txt) {
      document.querySelector(id).innerHTML = txt
    },
    async doNext() {
      let tabValid = true
      for (const item of this.competitionItems) {
        for (const info of item.infos) {
          info.inValid = false
          info.msg = ''
          if (info.type == 1) {
            if (info.isRequisiteItem) {
              let chechkCount = 0
              for (const input of info.inputs) {
                if (input.answer) {
                  ++chechkCount
                }
              }
              if (chechkCount == 0) {
                tabValid = false
                info.inValid = true
                info.msg = this.$t(
                  'content.accelerating.alert.txt48',
                  [info.name],
                )
              }
            }
          } else if (info.type == 2) {
            let chechkCount = 0
            for (const input of info.inputs) {
              if (!!input.answer) {
                ++chechkCount
              }
            }

            if (info.isRequisiteItem) {
              if (chechkCount == 0) {
                tabValid = false
                info.inValid = true
                info.msg = this.$t(
                  'content.accelerating.alert.txt48',
                  [info.name],
                )
              }
            }
            if (!info.inValid) {
              if (info.option) {
                if (info.option*1 < chechkCount) {
                  tabValid = false
                  info.inValid = true
                  info.msg = `${info.name}를 ${info.option}개 이하로 선택입니다`
                }
              }
            }
          } else if (info.type == 3) {
            if (info.isRequisiteItem) {
              for (const input of info.inputs) {
                if (!input.answer) {
                  tabValid = false
                  input.inValid = true
                  input.msg = input.msg = this.$t(
                    'content.accelerating.alert.txt47',
                    [info.name],
                  )
                } else {
                  input.inValid = false
                  input.msg = ''
                }
              }
            }
          } else if (info.type == 4) {
            if (info.isRequisiteItem) {
              for (const input of info.inputs) {
                if (!input.answer) {
                  tabValid = false
                  input.inValid = true
                  input.msg = this.$t(
                    'content.accelerating.alert.txt47',
                    [info.name],
                  )
                } else {
                  input.inValid = false
                  input.msg = ''
                }
              }
            }
          }
        }
      }

      if (tabValid) {
        ++this.currentTabId
      }
      ++this.keyIndex
    },
    /**
     * 기타입력 첨부파일 업로드
     */
    uploadEtcInfoFile(file, id) {
      const ids = id.slice(1, id.length).split("_")
      this.uploadFile(22, 0, this.userInfo.id, file)
        .then((data) => {
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].fileName = file.name
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].answer = data.id
          // ++this.keyIndex;
        })
        .catch(() => {
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].fileName = null
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].answer = null
          // ++this.keyIndex;
        });
    },
    /**
     * 기타입력 첨부파일 삭제
     */
    removeEtcInfoFile(id, tagName) {
      const ids = id.slice(1, id.length).split("_")
      const item = this.competitionItems[ids[0]*1].infos[ids[1]*1]
      const input = this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1]

      input.fileName = null
      input.answer = null
      if (item.isRequisiteItem) {
        input.inValid = true
        input.msg = `${tagName}을 입력하세요`
      } else {
        input.inValid = false
        input.msg = ''
      }
      ++this.keyIndex;
    },
    
    setAnswer() {
      if (this.accApplyTemporary.length > 0) {
        for (const item of this.competitionItems) {
          for (let info of item.infos) {
            if (info.type == 1) {
              info.inputs[0].answer = this.findRadioAnswer(info.competitionId, info.competitionItemId, info.id, 0)
            } else if (info.type == 2) {
              for (let input of info.inputs) {
                input.answer = this.findRadioAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
              }
            } else if (info.type == 3) {
              for (let input of info.inputs) {
                input.answer = this.findAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
              }
            } else if (info.type == 4) {
              for (let input of info.inputs) {
                const tmp = this.findFileAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
                if (tmp) {
                  input.fileName = tmp.fileName
                  input.answer = tmp.answer
                }
              }
            }
          }
        }
        ++this.keyIndex;
      }
    },
    findCheckAnswer2(idx, infoIdx) {
      // console.log('findCheckAnswer2(competitionId, competitionItemId, competitionItemInfoId, id)')
      const inputs = this.competitionItems[idx].infos[infoIdx];
      // console.log(inputs)
      const answers = []
      for (const tmp of inputs.inputs) {
        if (tmp.answer === 1) {
          answers.push( tmp.name )
        }
      }
      return answers.join(" / ");
    },
    findRadioAnswer2(idx, infoIdx, id) {
      // console.log('findRadioAnswer2(competitionId, competitionItemId, competitionItemInfoId, id)', idx, infoIdx, id)
      const inputs = this.competitionItems[idx].infos[infoIdx];
      for (const tmp of inputs.inputs) {
        if (tmp.id === id) {
          return tmp.name;
        }
      }
      return null;
    },
    findRadioAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          if (tmp.answer) return tmp.answer*1;
        }
      }
      return null;
    },
    findAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          // console.log('findAnswer', tmp.answer)
          if (tmp.answer) return  tmp.answer;
        }
      }
      return null;
    },
    findFileAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findFileAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          // console.log('findAnswer', tmp.answer)
          if (tmp.answer) return  {answer: tmp.answer, fileName: tmp.fileName};
        }
      }
      return null;
    },
    onFileChange(event) {
      const fileTargetId = event.target.id
      const fileData = event.target.files[0];

      const ids = fileTargetId.slice(1, fileTargetId.length).split("_")
      const item = this.competitionItems[ids[0]*1].infos[ids[1]*1]
      const ext = fileData.name.substring(fileData.name.lastIndexOf('.') + 1);
      if (item.option) {
        if (!item.option.toUpperCase().includes(ext.toUpperCase())) {
          item.inputs[ids[2]*1].inValid = true
          item.inputs[ids[2]*1].msg = `${item.option} 허용합니다`
          ++this.keyIndex
          return
        }
      } else {
        if (!this.checkMime.includes(ext.toLowerCase())) {
          item.inputs[ids[2]*1].inValid = true
          item.inputs[ids[2]*1].msg = `${this.checkMime.join(', ')} 허용합니다`
          ++this.keyIndex
          return
        }
      }
      item.inputs[ids[2]*1].inValid = false
      item.inputs[ids[2]*1].msg = ' '

      this.uploadCompetitionFile(22, 0, this.userInfo.id, fileData, item.competitionId, item.competitionItemId, item.id)
        .then((data) => {
          if (data.result) {
            this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].fileName = fileData.name
            this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].answer = data.data.id
            ++this.keyIndex;
          } else {
            if (data.code == '202') {
              item.inputs[ids[2]*1].inValid = true
              item.inputs[ids[2]*1].msg = `${item.option} 허용합니다`
              ++this.keyIndex
            }
          }
        }).catch(() => {
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].fileName = null
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].answer = null
          ++this.keyIndex;
        });
    },
    findFile(id) {
      const fileInput = this.$refs[id]
      if (fileInput) {
        fileInput[0].click()
      }
    },
    checkValidText(input, isRequisiteItem, tagName, id) {
      if (isRequisiteItem) {
        if (!input.answer) {
          input.inValid = true
          input.msg = `${tagName}을 입력하세요`
          document.querySelector(`#input_${id}`).classList.add('input-error')
        } else {
          input.inValid = false
          input.msg = ''
          document.querySelector(`#input_${id}`).classList.remove('input-error')
        }
        document.querySelector(`#error_${id}`).innerHTML = input.msg
      }
    },
    getAnswerCheckbox(inputs) {
      let answers = ''
      for (const input of inputs) {
        if (input.answer) {
          if (answers) {
            answers += ', '
          }
          answers += input.name
        }
      }
      return answers
    },
    getAnswerText(inputs) {
      let answers = ''
      for (const input of inputs) {
        if (input.answer) {
          if (answers) {
            answers += ', '
          }
          answers += input.answer
        }
      }
      return answers
    },
    getAnswerFileName(inputs) {
      let answers = ''
      for (const input of inputs) {
        console.log( input )
        if (input.fileName) {
          if (answers) {
            answers += ' / '
          }
          answers += input.fileName
        }
      }
      return answers
    },
    getOption(option) {
      if (option) {
        return option.split(',').map(o => {
          if (o.trim().charAt(0) == '.') {
            return o.trim()
          } else {
            return '.'+o.trim()
          }
        }).join(', ')
      }
    },
    async fileDown(fileObj) {
      // const fileObj = this.contentsDetail.fileList;
      console.log(fileObj)
      if (fileObj.length === 1) {
        const fileURL = fileObj[0].url;
        this.downloadFileV2(fileURL, fileObj[0].originalName);
      } else {
        const zip = new JSZip();
        for (let i = 0; i < fileObj.length; i++) {
          const v = fileObj[i];
          const fileURL = v.url;
          await this.getBinaryContent(zip, fileURL, v.originalName);
        }
        zip.generateAsync({
          type: 'blob',
        })
        .then((content) => {
          // console.log(content);
          FileSaver.saveAs(content, 'contents.zip');
        });
      }
    },
  },

  /**
   * 새로고침 방지
   */
  prohibiteRefresh(e) {
    alert( e.keyCode )
    if (e.keyCode === 116) { // F5키
      e.preventDefault();
    }
  },
};
</script>

<style>
.required-mark{ 
  position: relative;
  display: inline-block;
  width: 16px;
  height: 10px;
  text-indent: -9999px;
  color: transparent;
  line-height: 10px;
}
.contest-apply.step1 .title2,
.contest-apply.step2 .title2 {
  padding-top: 20px;
}
.contest-apply {
  padding: 30px 104px 5px;
}
.contest-apply .form table {
  margin-bottom: 20px;
}
.inner-sm {
  max-width: 1168px !important;
}

.share_link {
  color: #468fcc;
  font-weight: bold;
}
</style>